import React from 'react'
import "./UnsolicitedInput.css"
import { Input } from 'antd'

const UnsolicitedInput = ({name, value, onchange, onclick}) => {
  return (
    <div>
        <Input maxLength={name === "qty_prs_received" ? 3 : 200} name={name} value={value} onChange={onchange} onClick={onclick} />
    </div>
  )
}

export default UnsolicitedInput