//Profile
export const PROFILE_DETAIL_LOADING = "PROFILE_DETAIL_LOADING";
export const PROFILE_DETAIL_SUCCESS = "PROFILE_DETAIL_SUCCESS";
export const PROFILE_DETAIL_FAIL = "PROFILE_DETAIL_FAIL";

//Toggle Buttons
export const CHANING_TABS_WITH_CUSTOM_BUTTON =
  "CHANING_TABS_WITH_CUSTOM_BUTTON";
export const SAVE_CLOSE_TOGGLE = "SAVE_CLOSE_TOGGLE";
export const DASHBOARD_SINGLE_ROW_DATA = "DASHBOARD_SINGLE_ROW_DATA";
export const EXFACTORY_DATE = "EXFACTORY_DATE";
export const REQ_REC_DATE = "REQ_REC_DATE";
export const SRT_BRANDS = "SRT_BRANDS";
export const SRT_SEASONS = "SRT_SEASONS";
export const SRF_EXFACTORY_DATE = "SRF_EXFACTORY_DATE";
export const RESET_SEASON_FILTER = "RESET_SEASON_FILTER";
export const RESET_MASS_FILTER = "RESET_MASS_FILTER";
export const ROW_INDEX_FOR_AUTOSCROLL = "ROW_INDEX_FOR_AUTOSCROLL";
export const SELECT_UNSELECT_SRT_TABLE_ROWS = "SELECT_UNSELECT_SRT_TABLE_ROWS";
export const RESET_SRF_SEARCH_DATA = "RESET_SRF_SEARCH_DATA";
export const RESET_SRF_FIELDS = "RESET_SRF_FIELDS";
export const DASHBOARD_STATUS_UPDATE = "DASHBOARD_STATUS_UPDATE";
export const RESET_SRF_LIST = "RESET_SRF_LIST";
export const EXPORT_ROW_DATA = "EXPORT_ROW_DATA";
export const REQUEST_SECTION_RESET = "REQUEST_SECTION_RESET";
export const RESET_DASHBOARD_DATA = "RESET_DASHBOARD_DATA";
export const RESET_DASHBOARD_SEARCH = "RESET_DASHBOARD_SEARCH";
export const RESET_SRT_DATA = "RESET_SRT_DATA";
export const RESET_DASHBOARD_STYLE_DATA = "RESET_DASHBOARD_STYLE_DATA";
export const SRF_DISABLE_TOGGLE = "SRF_DISABLE_TOGGLE";
export const GRAPH_DATA_FROM_MICROSOFT = "GRAPH_DATA_FROM_MICROSOFT";
export const CLEAR_DATE_ON_BTN_CLICK = "CLEAR_DATE_ON_BTN_CLICK";
export const DROA_CHECKED_VARIABLE = "DROA_CHECKED_VARIABLE";
export const EXPAND_ACCORDION = "EXPAND_ACCORDION";
export const CLOSE_EMAIL_MODAL = "CLOSE_EMAIL_MODAL";

//Authentication
export const GET_AUTHENTICATION_LOADING = "GET_AUTHENTICATION_LOADING";
export const GET_AUTHENTICATION_SUCCESS = "GET_AUTHENTICATION_SUCCESS";
export const GET_AUTHENTICATION_FAIL = "GET_AUTHENTICATION_FAIL";

export const GET_GRAPH_DATA_LOADING = "GET_GRAPH_DATA_LOADING";
export const GET_GRAPH_DATA_SUCCESS = "GET_GRAPH_DATA_SUCCESS";
export const GET_GRAPH_DATA_FAIL = "GET_GRAPH_DATA_FAIL";

//Dashboard
export const STYLE_LIST_LOADING = "STYLE_LIST_LOADING";
export const STYLE_LIST_SUCCESS = "STYLE_LIST_SUCCESS";
export const STYLE_LIST_FAIL = "STYLE_LIST_FAIL";

export const STYLE_LIST_SEARCH_LOADING = "STYLE_LIST_SEARCH_LOADING";
export const STYLE_LIST_SEARCH_SUCCESS = "STYLE_LIST_SEARCH_SUCCESS";
export const STYLE_LIST_SEARCH_FAIL = "STYLE_LIST_SEARCH_FAIL";

export const STYLE_LIST_SEARCH_FILTER_LOADING =
  "STYLE_LIST_SEARCH_FILTER_LOADING";
export const STYLE_LIST_SEARCH_FILTER_SUCCESS =
  "STYLE_LIST_SEARCH_FILTER_SUCCESS";
export const STYLE_LIST_SEARCH_FILTER_FAIL = "STYLE_LIST_SEARCH_FILTER_FAIL";

export const STATUS_SAVER_LOADING = "STATUS_SAVER_LOADING";
export const STATUS_SAVER_SUCCESS = "STATUS_SAVER_SUCCESS";
export const STATUS_SAVER_FAIL = "STATUS_SAVER_FAIL";

export const STYLE_DETAILS_LOADING = "STYLE_DETAILS_LOADING";
export const STYLE_DETAILS_SUCCESS = "STYLE_DETAILS_SUCCESS";
export const STYLE_DETAILS_FAIL = "STYLE_DETAILS_FAIL";

export const DROA_NEEDED_LOADING = "DROA_NEEDED_LOADING";
export const DROA_NEEDED_SUCCESS = "DROA_NEEDED_SUCCESS";
export const DROA_NEEDED_FAIL = "DROA_NEEDED_FAIL";

export const ROA_RECEIVED_STATUS_LOADING = "ROA_RECEIVED_STATUS_LOADING";
export const ROA_RECEIVED_STATUS_SUCCESS = "ROA_RECEIVED_STATUS_SUCCESS";
export const ROA_RECEIVED_STATUS_FAIL = "ROA_RECEIVED_STATUS_FAIL";

export const DASHBOARD_REPORT_GENERATION_LOADING =
  "DASHBOARD_REPORT_GENERATION_LOADING";
export const DASHBOARD_REPORT_GENERATION_SUCCESS =
  "DASHBOARD_REPORT_GENERATION_SUCCESS";
export const DASHBOARD_REPORT_GENERATION_FAIL =
  "DASHBOARD_REPORT_GENERATION_FAIL";

export const EMAIL_STATUS_LOADING = "EMAIL_STATUS_LOADING";
export const EMAIL_STATUS_SUCCESS = "EMAIL_STATUS_SUCCESS";
export const EMAIL_STATUS_FAIL = "EMAIL_STATUS_FAIL";

export const TP_STATUS_DROPDOWN_UPDATE_LOADING =
  "TP_STATUS_DROPDOWN_UPDATE_LOADING";
export const TP_STATUS_DROPDOWN_UPDATE_SUCCESS =
  "TP_STATUS_DROPDOWN_UPDATE_SUCCESS";
export const TP_STATUS_DROPDOWN_UPDATE_FAIL = "TP_STATUS_DROPDOWN_UPDATE_FAIL";

export const UPDATE_SAMPLES_EMAIL_LOADING = "UPDATE_SAMPLES_EMAIL_LOADING";
export const UPDATE_SAMPLES_EMAIL_SUCCESS = "UPDATE_SAMPLES_EMAIL_SUCCESS";
export const UPDATE_SAMPLES_EMAIL_FAIL = "UPDATE_SAMPLES_EMAIL_FAIL";

export const GET_SAVED_EMAIL_LOADING = "GET_SAVED_EMAIL_LOADING";
export const GET_SAVED_EMAIL_SUCCESS = "GET_SAVED_EMAIL_SUCCESS";
export const GET_SAVED_EMAIL_FAIL = "GET_SAVED_EMAIL_FAIL";

export const SAVE_EMAIL_DETAILS_LOADING = "SAVE_EMAIL_DETAILS_LOADING";
export const SAVE_EMAIL_DETAILS_SUCCESS = "SAVE_EMAIL_DETAILS_SUCCESS";
export const SAVE_EMAIL_DETAILS_FAIL = "SAVE_EMAIL_DETAILS_FAIL";

export const DELETE_EMAIL_DETAILS_LOADING = "DELETE_EMAIL_DETAILS_LOADING";
export const DELETE_EMAIL_DETAILS_SUCCESS = "DELETE_EMAIL_DETAILS_SUCCESS";
export const DELETE_EMAIL_DETAILS_FAIL = "DELETE_EMAIL_DETAILS_FAIL";

export const DOWNLOAD_EMAIL_LOADING = "DOWNLOAD_EMAIL_LOADING";
export const DOWNLOAD_EMAIL_SUCCESS = "DOWNLOAD_EMAIL_SUCCESS";
export const DOWNLOAD_EMAIL_FAIL = "DOWNLOAD_EMAIL_FAIL";

export const SEND_EMAIL_LOADING = "SEND_EMAIL_LOADING";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAIL = "SEND_EMAIL_FAIL";

// Srt
export const GET_SRT_LIST_LOADING = "GET_SRT_LIST_LOADING";
export const GET_SRT_LIST_SUCCESS = "GET_SRT_LIST_SUCCESS";
export const GET_SRT_LIST_FAIL = "GET_SRT_LIST_FAIL";

export const MASS_SEARCH_FILTER_LOADING = "MASS_SEARCH_FILTER_LOADING";
export const MASS_SEARCH_FILTER_SUCCESS = "MASS_SEARCH_FILTER_SUCCESS";
export const MASS_SEARCH_FILTER_FAIL = "MASS_SEARCH_FILTER_FAIL";

export const SEASON_SEARCH_FILTER_LOADING = "SEASON_SEARCH_FILTER_LOADING";
export const SEASON_SEARCH_FILTER_SUCCESS = "SEASON_SEARCH_FILTER_SUCCESS";
export const SEASON_SEARCH_FILTER_FAIL = "SEASON_SEARCH_FILTER_FAIL";

export const BRAND_COMMENT_SAVER_LOADING = "BRAND_COMMENT_SAVER_LOADING";
export const BRAND_COMMENT_SAVER_SUCCESS = "BRAND_COMMENT_SAVER_SUCCESS";
export const BRAND_COMMENT_SAVER_FAIL = "BRAND_COMMENT_SAVER_FAIL";

export const DESIGN_COMMENT_SAVER_LOADING = "DESIGN_COMMENT_SAVER_LOADING";
export const DESIGN_COMMENT_SAVER_SUCCESS = "DESIGN_COMMENT_SAVER_SUCCESS";
export const DESIGN_COMMENT_SAVER_FAIL = "DESIGN_COMMENT_SAVER_FAIL";

export const BRAND_STATUS_SAVER_LOADING = "BRAND_STATUS_SAVER_LOADING";
export const BRAND_STATUS_SAVER_SUCCESS = "BRAND_STATUS_SAVER_SUCCESS";
export const BRAND_STATUS_SAVER_FAIL = "BRAND_STATUS_SAVER_LOADING";

export const DESIGN_STATUS_SAVER_LOADING = "DESIGN_STATUS_SAVER_LOADING";
export const DESIGN_STATUS_SAVER_SUCCESS = "DESIGN_STATUS_SAVER_SUCCESS";
export const DESIGN_STATUS_SAVER_FAIL = "DESIGN_STATUS_SAVER_LOADING";

export const SRT_DATA_SAVE_LOADING = "SRT_DATA_SAVE_LOADING";
export const SRT_DATA_SAVE_SUCCESS = "SRT_DATA_SAVE_SUCCESS";
export const SRT_DATA_SAVE_FAIL = "SRT_DATA_SAVE_FAIL";

export const SRT_EXPORT_LOADING = "SRT_EXPORT_LOADING";
export const SRT_EXPORT_SUCCESS = "SRT_EXPORT_SUCCESS";
export const SRT_EXPORT_FAIL = "SRT_EXPORT_FAIL";

export const SRT_EXPORT_DATA = "SRT_EXPORT_DATA";

export const SRT_REPORT_GENERATION_LOADING = "SRT_REPORT_GENERATION_LOADING";
export const SRT_REPORT_GENERATION_SUCCESS = "SRT_REPORT_GENERATION_SUCCESS";
export const SRT_REPORT_GENERATION_FAIL = "SRT_REPORT_GENERATION_FAIL";

export const SRT_COMMENT_UPDATE_LOADING = "SRT_COMMENT_UPDATE_LOADING";
export const SRT_COMMENT_UPDATE_SUCCESS = "SRT_COMMENT_UPDATE_SUCCESS";
export const SRT_COMMENT_UPDATE_FAIL = "SRT_COMMENT_UPDATE_FAIL";

export const SRT_SEARCH_FILTER_LOADING = "SRT_SEARCH_FILTER_LOADING";
export const SRT_SEARCH_FILTER_SUCCESS = "SRT_SEARCH_FILTER_SUCCESS";
export const SRT_SEARCH_FILTER_FAIL = "SRT_SEARCH_FILTER_FAIL";

export const SRT_SEASON_SEARCH_FILTER_LOADING =
  "SRT_SEASON_SEARCH_FILTER_LOADING";
export const SRT_SEASON_SEARCH_FILTER_SUCCESS =
  "SRT_SEASON_SEARCH_FILTER_SUCCESS";
export const SRT_SEASON_SEARCH_FILTER_FAIL = "SRT_SEASON_SEARCH_FILTER_FAIL";

export const SRT_BULK_SAVE_LOADING = "SRT_BULK_SAVE_LOADING";
export const SRT_BULK_SAVE_SUCCESS = "SRT_BULK_SAVE_SUCCESS";
export const SRT_BULK_SAVE_FAIL = "SRT_BULK_SAVE_FAIL";

export const GET_SRT_UNSOLICITED_LIST_LOADING = "GET_SRT_UNSOLICITED_LIST_LOADING";
export const GET_SRT_UNSOLICITED_LIST_SUCCESS = "GET_SRT_UNSOLICITED_LIST_SUCCESS";
export const GET_SRT_UNSOLICITED_LIST_FAIL = "GET_SRT_UNSOLICITED_LIST_FAIL";

export const SRT_UNSOLICITED_BULK_UPLOAD_LOADING = "SRT_UNSOLICITED_BULK_UPLOAD_LOADING";
export const SRT_UNSOLICITED_BULK_UPLOAD_SUCCESS = "SRT_UNSOLICITED_BULK_UPLOAD_SUCCESS";
export const SRT_UNSOLICITED_BULK_UPLOAD_FAIL = "SRT_UNSOLICITED_BULK_UPLOAD_FAIL";

export const SRT_UNSOLICITED_EXPORT_LOADING = "SRT_UNSOLICITED_EXPORT_LOADING";
export const SRT_UNSOLICITED_EXPORT_SUCCESS = "SRT_UNSOLICITED_EXPORT_SUCCESS";
export const SRT_UNSOLICITED_EXPORT_FAIL = "SRT_UNSOLICITED_EXPORT_FAIL";

export const SRT_UNSOLICITED_EDIT_LOADING = "SRT_UNSOLICITED_EDIT_LOADING";
export const SRT_UNSOLICITED_EDIT_SUCCESS = "SRT_UNSOLICITED_EDIT_SUCCESS";
export const SRT_UNSOLICITED_EDIT_FAIL = "SRT_UNSOLICITED_EDIT_FAIL";

// Srf
export const GET_SRF_LIST_LOADING = "GET_SRF_LIST_LOADING";
export const GET_SRF_LIST_SUCCESS = "GET_SRF_LIST_SUCCESS";
export const GET_SRF_LIST_FAIL = "GET_SRF_LIST_FAIL";

export const SRF_SAVE_LOADING = "SRF_SAVE_LOADING";
export const SRF_SAVE_SUCCESS = "SRF_SAVE_SUCCESS";
export const SRF_SAVE_FAIL = "SRF_SAVE_FAIL";

export const SRF_SEARCH_FILTER_LOADING = "SRF_SEARCH_FILTER_LOADING";
export const SRF_SEARCH_FILTER_SUCCESS = "SRF_SEARCH_FILTER_SUCCESS";
export const SRF_SEARCH_FILTER_FAIL = "SRF_SEARCH_FILTER_FAIL";

export const SRF_SEND_SAMPLE_REQUEST_LOADING =
  "SRF_SEND_SAMPLE_REQUEST_LOADING";
export const SRF_SEND_SAMPLE_REQUEST_SUCCESS =
  "SRF_SEND_SAMPLE_REQUEST_SUCCESS";
export const SRF_SEND_SAMPLE_REQUEST_FAIL = "SRF_SEND_SAMPLE_REQUEST_FAIL";

export const SRF_EDIT_LOADING = "SRF_EDIT_LOADING";
export const SRF_EDIT_SUCCESS = "SRF_EDIT_SUCCESS";
export const SRF_EDIT_FAIL = "SRF_EDIT_FAIL";

export const SRF_EXPORT_LOADING = "SRF_EXPORT_LOADING";
export const SRF_EXPORT_SUCCESS = "SRF_EXPORT_SUCCESS";
export const SRF_EXPORT_FAIL = "SRF_EXPORT_FAIL";

export const MULTI_SRF_LOADING = "MULTI_SRF_LOADING";
export const MULTI_SRF_SUCCESS = "MULTI_SRF_SUCCESS";
export const MULTI_SRF_FAIL = "MULTI_SRF_FAIL";

export const DELETE_SRF_COLOR_LOADING = "DELETE_SRF_COLOR_LOADING";
export const DELETE_SRF_COLOR_SUCCESS = "DELETE_SRF_COLOR_SUCCESS";
export const DELETE_SRF_COLOR_FAIL = "DELETE_SRF_COLOR_FAIL";
