import React, { useState } from "react";
import DropDown from "../../../UIComponents/DropDown/DropDown";
import "../Dickies/Dickies.css";
import SearchInput from "../../../UIComponents/SearchInput/SearchInput";
import { Button } from "antd";
import SrtTable from "../Table/SrtTable";
import { useSelector, useDispatch } from "react-redux";
import {
  getSrtInitialList,
  massSearchFilter,
  resetMassFilter,
  resetSeasonFilter,
  resetSrtData,
  seasonSearchFilter,
  selectUnselectSrtTableRows,
  srtBulkSave,
  srtExport,
  srtSeasonSearchFilter,
} from "../../../Redux/actions/Srt/Srt";
import Select from "../../../UIComponents/DropDown/DropDown";
import { DownloadOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import { SyncOutlined } from "@ant-design/icons";
import Cookies from "js-cookie";
import { useRef } from "react";

const Eddie = () => {
  const dispatch = useDispatch();
  const fileRef = useRef();
  const [searchFilter, setSearchFilter] = useState();
  const [requestFilter, setRequestFilter] = useState();
  const [teamFilter, setTeamFilter] = useState();
  const [statusFilter, setStatusFilter] = useState();
  const [vendorFilter, setVendorFilter] = useState();
  const [colorFilter, setColorFilter] = useState();
  const [sizeFilter, setSizeFilter] = useState();
  const [seasonFilter, setSeasonFilter] = useState();
  const [singleSeason, setSingleSeason] = useState([]);
  const [singleColor, setSingleColor] = useState([]);
  const [singleSize, setSingleSize] = useState([]);
  const [singleFactory, setSingleFactory] = useState([]);

  const srtDataList = useSelector((state) => state?.Srt?.getSrtDataList);
  const styleListData = useSelector((state) => state?.Dashboard?.styleList);
  const brandNameFromApi = useSelector((state) => state?.Srt?.srtBrandNames);
  const seasonFilterApiData = useSelector(
    (state) => state?.Srt?.seasonSearchFilterResult
  );
  const srtSeasonData = useSelector((state) => state?.Srt?.srtSeasonSearch);
  const exportArraySrt = useSelector((state) => state?.Srt?.srtExportArrayData);
  const srtRefresh = useSelector((state) => state?.Srt?.srtRefreshSpinner);

  const intialSearch = () => {
    if (seasonFilterApiData && seasonFilterApiData?.length > 0) {
      return seasonFilterApiData;
    } else if (srtSeasonData && srtSeasonData?.length > 0) {
      return srtSeasonData;
    } else if (srtDataList) {
      return srtDataList;
    } else return [];
  };

  useEffect(() => {
    const colorArray = intialSearch()?.map((item) => item?.color_code);
    setSingleColor([...new Set(colorArray)]);
    const sizeArray = intialSearch()?.map((item) => item?.size);
    setSingleSize([...new Set(sizeArray)]);
  },[intialSearch()])

  useEffect(() => {
    if (styleListData && styleListData.length > 0) {
      const seasonArray = styleListData?.map((item) => item?.season);
      setSingleSeason([...new Set(seasonArray)]);
      const vendorArray = styleListData?.map((item) => item?.factory);
      const uniqueNames = new Set();
      vendorArray.forEach((names) => {
        if (Array.isArray(names)) {
          names.forEach((name) => {
            uniqueNames.add(name);
          });
        } else {
          uniqueNames.add(names);
        }
      });

      const uniqueArray = Array.from(uniqueNames).sort();
      setSingleFactory(uniqueArray);
    }
  }, [styleListData]);

  const srtDataForApi = JSON.parse(localStorage.getItem("srtData"));

  // useEffect(() => {
  //   dispatch(
  //     getSrtInitialList(
  //       srtDataForApi?.brand ? srtDataForApi?.brand : "PD",
  //       srtDataForApi?.style ? srtDataForApi?.style : "",
  //       srtDataForApi?.factory ? srtDataForApi?.factory : "",
  //       srtDataForApi?.rejected_date ? srtDataForApi?.rejected_date : "",
  //       0
  //     )
  //   );
  // }, [
  //   srtDataForApi?.brand,
  //   srtDataForApi?.factory,
  //   srtDataForApi?.style,
  //   srtDataForApi?.rejected_date,
  // ]);

  const option = [
    {
      value: "S23",
      label: "S23",
    },
    {
      value: "F23",
      label: "F23",
    },
  ];
  const exports = [
    {
      value: "export",
      label: "export",
    },
  ];
  const requests = [
    {
      value: "",
      label: "-",
    },
    {
      value: 1,
      label: "Request 1",
    },
    {
      value: 2,
      label: "Request 2",
    },
    {
      value: 3,
      label: "Request 3",
    },
    {
      value: 4,
      label: "Request 4",
    },
    {
      value: 5,
      label: "Request 5",
    },
    {
      value: 6,
      label: "Request 6",
    },
    {
      value: 7,
      label: "Request 7",
    },
    {
      value: 8,
      label: "Request 8",
    },
    {
      value: 9,
      label: "Request 9",
    },
    {
      value: 10,
      label: "Request 10",
    },
  ];

  const seasons = [
    {
      value: "F24",
      label: "F24",
    },
    {
      value: "S25",
      label: "S25",
    },
    {
      value: "F25",
      label: "F25",
    },
  ]


  const team = [
    {
      value: "",
      label: "-",
    },
    {
      value: "BRAND",
      label: "BRAND",
    },
    {
      value: "DESIGN",
      label: "DESIGN",
    },
    {
      value: "PD",
      label: "PD",
    },
    {
      value: "QA",
      label: "QA",
    },
  ];

  const statusofsrt = [
    {
      value: "",
      label: "-",
    },
    {
      value: "Set_up_Ready",
      label: "Set-up Ready",
    },
    {
      value: "Approved",
      label: "Approved",
    },
    {
      value: "Override",
      label: "Override",
    },
    {
      value: "N/A",
      label: "N/A",
    },
    {
      value: teamFilter !== "BRAND" ? "Rejected" : "Dropped",
      label: teamFilter !== "BRAND" ? "Rejected" : "Dropped",
    },
  ];

  const onSearchChange = (e) => {
    setSearchFilter(e.target.value);
  };

  const onRequestChange = (value) => {
    setRequestFilter(value);
  };

  const onTeamChange = (value) => {
    setTeamFilter(value);
  };

  const onStatusChange = (value) => {
    setStatusFilter(value);
  };

  const onSeasonChange = (value) => {
    dispatch(srtSeasonSearchFilter("PD", value == undefined ? "" : value, 0));
    setSeasonFilter(value)
    dispatch(resetMassFilter([]));
    dispatch(resetSrtData([]));
  };

  const onFactoryChange = (value) => {
    setVendorFilter(value);
  };

  const onColorChange = (value) => {
    setColorFilter(value)
  }
  
  const onSizeChange = (value) => {
    setSizeFilter(value)
  }

  const onSubmitClick = () => {
    localStorage.setItem(
      "srtMassData",
      JSON.stringify({
        searchFilter: searchFilter,
        brandfilter: "PD",
        colorFilter: colorFilter,
        sizeFilter: sizeFilter,
        statusFilter: statusFilter,
        vendorFilter: vendorFilter,
        sale_samplefilter: 0,
        seasonFilter: seasonFilter
      })
    );
    dispatch(
      massSearchFilter(
        searchFilter ? searchFilter : "",
        "PD",
        colorFilter ? colorFilter : "",
        sizeFilter ? sizeFilter : "",
        statusFilter ? statusFilter : "",
        vendorFilter ? vendorFilter : "",
        0,
        seasonFilter ? seasonFilter : ""
      )
    );
  };
  const exportToExcelEddie = () => {
    if (searchFilter && vendorFilter && exportArraySrt.length > 0) {
      toast.error("!Please select either style and Vendor Filter or style ids");
    }
    else if (vendorFilter === null || vendorFilter === undefined && searchFilter  && exportArraySrt.length === 0) {
      toast.error("!Please select either style and Vendor Filter or style ids");
    }
    else if (searchFilter === null || searchFilter === undefined && vendorFilter  && exportArraySrt.length === 0) {
      toast.error("!Please select either style and Vendor Filter or style ids");
    }
    else {
      dispatch(srtExport({  style: searchFilter === null || searchFilter === undefined ? "" : searchFilter,
        factory: vendorFilter === null || vendorFilter === undefined ? "" : vendorFilter,style_pids: exportArraySrt, sale_sample: "0" }));
      dispatch(selectUnselectSrtTableRows([]));
      dispatch(srtEXportArray([]));
    }
  };
  const onRefresh = () => {
    localStorage.removeItem("srtMassData");
    localStorage.removeItem("currentPage");
    localStorage.setItem(
      "srtData",
      JSON.stringify({
        brand: "PD",
        style: "",
        factory: "",
        rejected_date: "",
        sale_sample: 0
      })
    );
    dispatch(
      getSrtInitialList(
        srtDataForApi?.brand ? srtDataForApi?.brand : "PD",
        srtDataForApi?.style ? srtDataForApi?.style : "",
        srtDataForApi?.factory ? srtDataForApi?.factory : "",
        srtDataForApi?.rejected_date ? srtDataForApi?.rejected_date : "",
        0,
        srtDataForApi?.season ? srtDataForApi?.season : ""
      )
    );
    dispatch(resetSeasonFilter([]));
    dispatch(resetMassFilter([]));
    setSearchFilter();
    setColorFilter();
    setSizeFilter();
    setStatusFilter();
    setVendorFilter();
  };
  const onSearchEnter = (event) => {
    if (event.key === "Enter") {
      if (searchFilter === null) {
        toast.error("Please Enter Details");
      } else {
        localStorage.setItem(
          "srtMassData",
          JSON.stringify({
            searchFilter: searchFilter,
            brandfilter: "PD",
            colorFilter: "",
            sizefilter: "",
            statusFilter: "",
            vendorFilter: "",
            sale_samplefilter: 0,
            seasonFilter : ""
          })
        );
        dispatch(
          massSearchFilter(
            searchFilter ? searchFilter : "",
            "PD",
            "",
            "",
            "",
            "",
            0,
            ""
          )
        );
        setSearchFilter();
      }
    }
  };

  const onSrtBulkUpload = (e) => {
    console.log("adddingbulk", e.target);
    let formData = new FormData();
    formData.append("files", e.target.files[0]);
    formData.append("sale_sample", "0");
    dispatch(srtBulkSave(formData, brandNameFromApi));
    e.target.value = null;
    formData = new FormData();
  };

  return (
    <div className="dickies-main-container">
      <div className="dickies-first-two-dropdowns">
        <div className="dickies-drop-down-one">
          <DropDown
            allowClear
            onChange={onSeasonChange}
            placeholder={"Season"}
            option={seasons}
            value={seasonFilter}
          />
        </div>
        <h2>PD & REDEVELOPMENT</h2>
        <div className="dickies-drop-down-two">
          <Button icon={<DownloadOutlined />} onClick={exportToExcelEddie}>
            Export
          </Button>
        </div>
      </div>

      {/* search dropdown button */}
      <div className="search-dropdown-button">
        <div className="dickies-search-input">
          <SearchInput
            onKeyPress={onSearchEnter}
            onChange={onSearchChange}
            value={searchFilter}
            placeholder={"Search by Parent Style"}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
            }}
          />
        </div>
        <div className="dropdown-width dickies-drop-down-five">
          <Select
            allowClear
            placeholder={"Vendor"}
            data={singleFactory}
            value={vendorFilter}
            onChange={onFactoryChange}
          ></Select>
        </div>
        <div className="dropdown-width dickies-drop-down-five">
          <Select
            allowClear
            placeholder={"Color"}
            data={singleColor}
            value={colorFilter}
            onChange={onColorChange}
          ></Select>
        </div>
        <div className="dropdown-width dickies-drop-down-five">
          <Select
            allowClear
            placeholder={"Size"}
            data={singleSize}
            value={sizeFilter}
            onChange={onSizeChange}
          ></Select>
        </div>
        <div className="dropdown-width dickies-drop-down-five">
          <DropDown
            allowClear
            onChange={onStatusChange}
            placeholder={"Status"}
            option={statusofsrt}
            value={statusFilter}
          />
        </div>
        <div className="sync-icon">
          <SyncOutlined spin={srtRefresh} onClick={onRefresh} />
        </div>
        <div>
          <Button
            onClick={onSubmitClick}
            className="dickies-button"
            children={"SUBMIT"}
          />
        </div>

        <div>
          <Button
            className="dickies-button"
            onClick={() => fileRef.current.click()}
          >
            Bulk Update
          </Button>
          <input
            type="file"
            onChange={onSrtBulkUpload}
            accept="image/jpeg/png/jpg , application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            ref={fileRef}
            hidden
          />
        </div>
      </div>
      <div className="srt-table-container">
        <SrtTable />
      </div>
    </div>
  );
};

export default Eddie;
